import React, { ReactNode } from 'react';
import { ToastContainer, Zoom } from 'react-toastify';

import LanguageChange from '@components/layout/LanguageChange/LanguageChange';
import { useTabletOrWider } from '@src/utils/mediaQuery';

import Inner from '../inner/Inner';

import styles from './LayoutPhoto.module.sass';
import Photo from './photo/Photo';

// Props
type LayoutPhotoProps = {
  withLogo?: boolean;
  children: ReactNode;
};

const LayoutPhoto: React.FC<LayoutPhotoProps> = ({ withLogo, children }) => {
  const isTabletOrWider = useTabletOrWider();

  return (
    <>
      <div className={styles.container}>
        <LanguageChange className={styles.languages} />

        {isTabletOrWider && <Photo />}

        <Inner withLogo={withLogo}>
          <ToastContainer
            autoClose={5000}
            hideProgressBar
            className="notification"
            draggable
            pauseOnHover
            closeOnClick={false}
            pauseOnFocusLoss
            limit={2}
            transition={Zoom}
          />
          {children}
        </Inner>
      </div>
    </>
  );
};

export default LayoutPhoto;
