import { useTranslation } from 'next-i18next';
import React, { ReactNode } from 'react';

import ZionodesLogoDarkTheme from '@icons/ZionodesLogoDarkPronounced.svg';
import ZionodesLogo from '@icons/menu/zionodesPronounced.svg';
import { useCurrentTheme, THEME } from '@src/api/local/theme';

import styles from './Inner.module.sass';

// Props
type InnerProps = {
  withLogo?: boolean;
  children: ReactNode;
};

const Inner: React.FC<InnerProps> = ({ withLogo = true, children }) => {
  const { t, i18n } = useTranslation('login');

  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  return (
    <div className={styles.inner}>
      <div className={styles.top}>
        {withLogo && (
          <div className={styles.heading}>
            {isLightTheme ? <ZionodesLogo /> : <ZionodesLogoDarkTheme />}
            <h1>Zionodes</h1>
          </div>
        )}
        {children}
      </div>
      <div className={styles.bottom}>
        <a
          href={`https://www.zionodes.com/${i18n.language === 'zh' ? 'zh/' : ''}terms-of-use`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('terms')}
        </a>
        {t('and')}
        <a
          href={`https://www.zionodes.com/${i18n.language === 'zh' ? 'zh/' : ''}privacy-policy`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {t('privacy')}
        </a>
      </div>
    </div>
  );
};

export default Inner;
