import { isValidPhoneNumber } from 'libphonenumber-js';
import { i18n } from 'next-i18next';

type ValidationType = (value: string) => string | undefined;

export const required = (value: string) =>
  value ? undefined : i18n?.t('common:Validators.required');

export const requiredFile = (value: File) =>
  value && value.size <= 2000000 ? undefined : i18n?.t('common:Validators.requiredFile');

export const maxFileSizeFactory = (fileSize: number, message: string) => (value?: File) =>
  !value || value.size < fileSize ? undefined : message;

export const max2MB = maxFileSizeFactory(2000000, i18n?.t('common:Validators.fileSize') || '');

export const minLength = (min: number) => (value: string) =>
  value && value.length >= min ? undefined : `${i18n?.t('common:Validators.minLength')}${min}`;

export const maxLength = (max: number) => (value: string) =>
  (value || '').length <= max ? undefined : `${i18n?.t('common:Validators.maxLength')}${max}`;

export const maxValue = (max: string) => (value: string) =>
  value && +value <= +max ? undefined : `${i18n?.t('common:Validators.maxValue')}${max}`;

export const minValue = (min: string) => (value: string) =>
  value && +value >= +min ? undefined : `${i18n?.t('common:Validators.minValue')}${min}`;

export const composeValidators =
  (...validators: ValidationType[]) =>
  (value: string) =>
    validators.reduce(
      (error: string | undefined, validator: ValidationType) => error || validator(value),
      undefined,
    );

export const differentPasswords = (password1: string) => (password2: string) =>
  password2 && password1 && password2 === password1
    ? undefined
    : i18n?.t('common:Validators.differentPasswords');

function isEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export const isStrongPassword = (password: string) => {
  const re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/;
  return re.test(password) ? undefined : i18n?.t('common:Validators.validateStrongPassword');
};

function isUSDTAddress(address: string) {
  let reETH: RegExp = /^0x[a-fA-F0-9]{40}$/;
  const reTRX = /T[A-Za-z1-9]{33}/;

  return reETH.test(address) || reTRX.test(address);
}

export const validateEmail = (value?: string) =>
  !value || isEmail(value) ? undefined : i18n?.t('common:Validators.validateEmail');

export const validatePhone = (value?: string) =>
  value && isValidPhoneNumber(value) ? undefined : i18n?.t('common:Validators.validatePhone');

export const validateUSDTAddress = (value?: string) =>
  !value || isUSDTAddress(value) ? undefined : i18n?.t('common:Validators.validateUSDTAddress');

export const validateWithdrawalAmount = (value?: string) =>
  !value || parseFloat(value) || parseFloat(value) < 1 || parseFloat(value) >= 10000000000000000000
    ? undefined
    : i18n?.t('common:Validators.validateWithdrawalAmount');

export const validateTopUpBalanceAmount = (value: string) =>
  +value > 0 ? undefined : i18n?.t('common:Validators.validateTopUpBalanceAmount');
