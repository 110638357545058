import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';

// Icons
import LoginPhoto from '@icons/LoginPhoto.svg';
import LoginPhotoDarkTheme from '@icons/LoginPhotoDarkTheme.svg';
import { useCurrentTheme, THEME } from '@src/api/local/theme';
import StyledCard from '@ui/StyledCard';
import Item1 from 'public/icons/menu/1.svg';
import Item2 from 'public/icons/menu/2.svg';
import Item3 from 'public/icons/menu/3.svg';
import Item4 from 'public/icons/menu/4.svg';

import s from './Photo.module.sass';

const Photo = () => {
  const { t, i18n } = useTranslation(['common']);

  const colorTheme = useCurrentTheme();
  const isLightTheme = colorTheme.data?.theme === THEME.light;

  const content = useMemo(
    () => [
      {
        title: t('common:Menu.item1'),
        icon: <Item1 />,
      },
      {
        title: t('common:Menu.item2'),
        icon: <Item2 />,
      },
      {
        title: t('common:Menu.item3'),
        icon: <Item3 />,
      },
      {
        title: t('common:Menu.item4'),
        icon: <Item4 />,
      },
    ],
    [t],
  );

  return (
    <span className={s.container}>
      {isLightTheme ? (
        <>
          <div className={isLightTheme ? s.content : s.darkThemeContent}>
            <h2 className={s.header}>{t('common:Menu.header')}</h2>
            {content.map(
              (item, index) =>
                !(i18n.language === 'en' && index === content.length - 1) && (
                  <div className={s.item} key={item.title}>
                    <StyledCard
                      theme="smallRadius"
                      className={s.card}
                      contentClassName={s.cardContent}
                    >
                      {item.icon}
                    </StyledCard>
                    <span className={s.text}>{item.title}</span>
                  </div>
                ),
            )}
          </div>

          <div className={s.photoWrapper}>
            <LoginPhoto />
          </div>
        </>
      ) : (
        <div className={s.photoWrapperDarkTheme}>
          <LoginPhotoDarkTheme />
        </div>
      )}
    </span>
  );
};

export default Photo;
