import { FORM_ERROR } from 'final-form';

type FieldSingleError<TParams> = {
  [Field in keyof TParams]?: TParams[Field] extends object
    ? FieldSingleError<TParams[Field]>
    : string;
};
type ExtractFieldSingleErrorResult<TParams> = FieldSingleError<TParams> & { [FORM_ERROR]?: string };
export function extractFieldSingleError<TValidationErrors>(
  errors: TValidationErrors,
): ExtractFieldSingleErrorResult<TValidationErrors> {
  const fieldError: Record<string, any> = {};

  if (errors) {
    Object.entries(errors).forEach(([field, error]) => {
      if (error) {
        if (field === 'non_field_errors') {
          [fieldError[FORM_ERROR]] = error;
        } else {
          if (Array.isArray(error) && error.some((el) => el?.constructor === Object)) {
            fieldError[field] = error.map((el) => extractFieldSingleError(el));
          } else {
            [fieldError[field]] = error;
          }
        }
      }
    });
  }

  return fieldError as ExtractFieldSingleErrorResult<TValidationErrors>;
}
